<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_analysis_sys_page relative w1240">
			<div class="analysis_sys_page_con">
				<!-- <div class="block20"></div> -->
				<!-- <div class="analysis_sys_page_title fontsize18">高级检索</div> -->
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">商标名称</div>
					<div class="inputdiv">
						<div class="input">
							<el-input placeholder="请输入内容" v-model="formobj.title"></el-input>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">国际分类号</div>
					<div class="inputdiv">
						<div class="input" v-for="(item,index) in internationalClassList" :key="index">
							<el-input v-model="item.value" placeholder="请输入内容"></el-input>
							<div v-if="index<internationalClassList.length-1" @click="delkeyword(index)" class="editicon"><img src="../assets/static/yx_close_red.png"/></div>
							<div v-else class="editicon" @click="addkeyword"><img src="../assets/static/add_icon.png"/></div>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">申请日期</div>
					<div class="input_center">
						<el-date-picker v-model="formobj.applyDate" type="date" placeholder="请选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">注册日期</div>
					<div class="input_center">
						<el-date-picker v-model="formobj.registDate" type="date" placeholder="请选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">商标类型</div>
					<div class="input_center">
						<el-select v-model="formobj.trademarkType" placeholder="请选择">
							<el-option label="不限" :value="null"></el-option>
						    <el-option v-for="item in trademarkTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">申请人</div>
					<div class="inputdiv">
						<div class="input">
							<el-input v-model="formobj.applicant" placeholder="请输入内容"></el-input>
						</div>
					</div>
				</div>
				<div class="analysis_sys_selectobj fontsize16">
					<div class="titlediv titlelong">代理机构</div>
					<div class="inputdiv">
						<div class="input" v-for="(item,index) in agency" :key="index">
							<el-input v-model="item.value" placeholder="请输入内容"></el-input>
							<div v-if="index<agency.length-1" @click="delagency(index)" class="editicon"><img src="../assets/static/yx_close_red.png"/></div>
							<div v-else class="editicon" @click="addagency"><img src="../assets/static/add_icon.png"/></div>
						</div>
					</div>
				</div>
				<div class="btnlist fontsize12">
					<div class="btn_clear" @click="clickClearAll">清除/重置</div>
					<div class="btn_click" @click="initData">查询检索</div>
					<div class="btn_fenxi"></div>
					<div class="btn_lineSel">
						<el-dropdown trigger="click">
						    <div class="text">
						    	<img class="mixicon" src="../assets/static/Frame2036.png"/>
						    	<div class="span fontsize12">列表显示</div>
								<img class="mixicon" src="../assets/static/Frame2037.png"/>
						    </div>
						    <el-dropdown-menu slot="dropdown">
						      <el-dropdown-item command="line">列表显示</el-dropdown-item>
						    </el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<div class="block20"></div>
				<!-- 内容，列表 -->
				<div class="analysis_sys_datalist">
					<div class="thlist fontsize16">
						<div class="th th8">商标名称</div>
						<div class="th th9">商标图片</div>
						<div class="th th10">商标号码</div>
						<div class="th th11">申请日期</div>
						<div class="th th12">是否域名商标</div>
						<div class="th th13">操作</div>
					</div>
					<div class="tdlist">
						<div class="itemlist" v-for="(item,index) in dataList" :key="index">
							<div class="tditem fontsize16">
								<div class="td td8">{{item.title}}</div>
								<div class="td td9">
									<img class="icon" style="object-fit:contain;" :src="item.images || require('../assets/static/noimg.png')"/>
								</div>
								<div class="td td10">{{item.trademarkNo}}</div>
								<div class="td td11">{{item.applyDate}}</div>
								<div class="td td12">{{item.domainFlag==0?'否':'是'}}</div>
								<div class="td td13" @click="handleShowMore(index)">
									<img class="icon" :class="{'normal':item.isShowMore}" src="../assets/static/Frame2037.png"/>
								</div>
							</div>
							<div class="moretd_box" v-if="item.isShowMore">
								<div class="left">
									<div class="titlename fontsize16">{{item.title}}</div>
									<div class="descitem fontsize14">
										<div class="text">国际分类：{{item.internationalClass}}</div>
										<div class="text">商标类型：{{item.trademarkTypeName}}</div>
										<div class="text">截止日期：{{item.endDate}}</div>
										<div class="text">商标状态：{{item.state==0?'有效':"无效"}}</div>
										<div class="text">注册号：{{item.registNo}}</div>
										<div class="text">申请日期：{{item.applyDate}}</div>
										<div class="text">注册日期：{{item.registDate}}</div>
										<div class="text">申请人：{{item.applicant}}</div>
										<div class="text">代理机构：{{item.agency}}</div>
									</div>
								</div>
								<div class="right">
									<img class="img" :src="item.images || require('../assets/static/noimg.png')"/>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<div class="block30" v-if="dataList.length<=0"></div>
				<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
				<div class="block30"></div>
				<div class="phonenone" style="text-align:right">
				    <el-pagination
				          @current-change="handleCurrentChange"
				          :current-page.sync="currentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next, jumper"
				          :total="total">
				    </el-pagination>
				</div>
				<div class="pcnone" style="text-align:right">
				    <el-pagination
					      small
				          @current-change="handleCurrentChange"
				          :current-page.sync="currentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next"
				          :total="total">
				    </el-pagination>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			keyword:"",
			trademarkTypeList:[],//商标类型
			internationalClassList:[],//国际号
			agency:[],//代理
			formobj:{
				title:"",
				internationalClassList:[],//主分类号
				trademarkType:null,//商标类型ID
				agency:[],//代理机构
				applicant:"",//申请人
				applyDate:"",//申请日期
				registDate:"",//注册日期
			},
		};
	},
	mounted: function() {
		if(this.$route.query){
			if(this.$route.query.key){
				//console.log(this.$route.query.key)
				this.keyword = this.$route.query.key
				this.keywordVaule = [{value: this.keyword}]
			}
			if(this.$route.query.typeId){
				this.formobj.typeId = parseInt(this.$route.query.typeId)
			}
		}
		this.getTrademarkType()
		//号码是否填写
		this.addkeyword()
		this.addagency()
		this.initData()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//清除重置
		clickClearAll(){
			this.$router.push({ path: '/trademark'})
			this.$router.go(0);
		},
		//处理对象
		initData(){
			this.currentPage = 1
			this.getdataList()
		},
		//展开收起
		handleShowMore(index){
			var isShowMore = this.dataList[index].isShowMore
			this.dataList[index].isShowMore = !isShowMore
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.getdataList()
		},
		//获取(文章)
		getdataList() {
			var _this = this;
			var params = this.formobj
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			//g国际分类号
			var internationalClassList = []
			var list1 = this.internationalClassList
			for(var i=0;i<list1.length;i++){
				if(list1[i].value){
					internationalClassList.push(list1[i].value)
				}
			}
			params["internationalClassList"] = internationalClassList
			//代理机构
			var agency = []
			for(var j=0;j<this.agency.length;j++){
				if(this.agency[j].value){
					agency.push(this.agency[j].value)
				}
			}
			params["agency"] = agency
			//console.log(params)
			this.$http.post('trademarkModule/trademark/page', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						//展示更多
						item["isShowMore"] = false
					})
					_this.total = res.data.total
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//增加分类和
		addkeyword(){
			var obj = {
				value:""
			}
			this.internationalClassList.push(obj)
		},
		//删除分类号
		delkeyword(index){
			this.internationalClassList.splice(index,1)
		},
		//增加代理机构
		addagency(){
			var obj = {
				value:""
			}
			this.agency.push(obj)
		},
		//删除机构
		delagency(index){
			this.agency.splice(index,1)
		},
		//获取商标可类型
		getTrademarkType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888,
			};
			this.$http.post('trademarkModule/trademark/getTrademarkType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.trademarkTypeList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
